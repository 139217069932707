import * as NProgress from "nprogress";
import { PROVINCE, SERVICE, PROVINCE_FOR_SELECT } from "@/src/const/variable";
import { notFound } from "next/navigation";
import { DateTime } from "luxon";

export const strVNForSearch = (str) => {
  return str
    ? str
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase()
    : str;
};

export const removeUndefined = (obj) => {
  for (var prop in obj) {
    if (
      obj[prop] === null ||
      obj[prop] === undefined ||
      obj[prop] === "" ||
      obj[prop]?.length == 0
    ) {
      delete obj[prop];
    }
  }
  return obj;
};

export const wrapperRouterPush = (router, link) => {
  NProgress.start();
  router.push(link);
  // setTimeout(() => {
  //   NProgress.done();
  // }, 10000);
};

export const formatWorkingDays = (workingDays) => {
  const sortedDays = workingDays;
  let result = "";
  let startRange = sortedDays[0].id;

  for (let i = 1; i < sortedDays.length; i++) {
    if (sortedDays[i].id !== sortedDays[i - 1].id + 1) {
      if (result !== "") {
        result += ", ";
      }
      if (startRange === sortedDays[i - 1].id) {
        result += startRange === 8 ? "Chủ nhật" : `Thứ ${startRange}`;
      } else {
        result += `Thứ ${startRange} -`;
        if (sortedDays[i - 1].id === 8) {
          result += " Chủ nhật";
        } else {
          result += ` Thứ ${sortedDays[i - 1].id}`;
        }
      }
      startRange = sortedDays[i].id;
    }
  }

  if (result !== "") {
    result += ", ";
  }
  if (startRange === sortedDays[sortedDays.length - 1].id) {
    result += startRange === 8 ? "Chủ nhật" : `Thứ ${startRange}`;
  } else {
    result += `Thứ ${startRange} - `;
    if (sortedDays[sortedDays.length - 1].id === 8) {
      result += " Chủ nhật";
    } else {
      result += `  Thứ ${sortedDays[sortedDays.length - 1].id}`;
    }
  }

  return result;
};

export const ratingPointToText = (point) => {
  if (point >= 1.0 && point <= 1.99) {
    return "Kém";
  } else if (point >= 2 && point <= 2.99) {
    return "Trung bình";
  } else if (point >= 3.0 && point <= 3.99) {
    return "Tốt";
  } else if (point >= 4.0 && point <= 4.99) {
    return "Rất tốt";
  } else if (point == 5) {
    return "Xuất sắc";
  } else {
    return "";
  }
};

export const toSlug = (input) => {
  return (
    input
      ?.toLowerCase()
      .replace(/-/g, " ")
      .replace(/\s+/g, "-") // Thay thế khoảng trắng bằng dấu gạch ngang
      .normalize("NFD") // Chuyển chuỗi về dạng Unicode Normalization Form D
      .replace(/[\u0300-\u036f]/g, "") // Loại bỏ các ký tự dấu thanh và dấu mũ
      // .replace(/[^a-z0-9-]/g, "") // Loại bỏ các ký tự không phải là chữ cái, số, hoặc dấu gạch ngang
      .replace(/đ/g, "d")
      .replace(/Đ/g, "D")
      .replace(/[^a-z0-9-]/g, "")
  );
};

export const capitalizeFirstLetters = (str) => {
  // Tách chuỗi thành mảng các từ
  var words = str.split(" ");

  // Lặp qua mỗi từ và chuyển đổi chữ cái đầu tiên thành in hoa
  for (var i = 0; i < words.length; i++) {
    words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
  }

  // Kết hợp lại mảng các từ thành một chuỗi mới và trả về
  return words.join(" ");
};

export function isObjectEmpty(obj) {
  // Kiểm tra bằng cách lặp qua tất cả các thuộc tính của đối tượng
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) {
      return false; // Nếu có ít nhất một thuộc tính, đối tượng không rỗng
    }
  }
  return true; // Nếu không có thuộc tính nào, đối tượng rỗng
}

export const convertPhone = (phone) => {
  // Chuyển đầu số 0 hoặc 84 thành +84
  phone = phone?.replace(/^0/, "84")?.replace(/^\+84/, "84");
  return phone;
};
//Chuẩn hóa giá trị tệp từ sự kiện đầu vào.
export const normFile = (e) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e?.fileList;
};
//Chuyển đổi file thành chuỗi Base64.
export const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
//Các option config cho xử lý hình ảnh.
export const options = {
  maxSizeMB: 1,
  maxWidthOrHeight: 1920,
  useWebWorker: true,
  initialQuality: 0.8,
};

export const convertYear = (duration_time) => {
  if (duration_time == 0) {
    return `Hoàn thành trong ngày`;
  }
  if (duration_time < 12) {
    return `${duration_time} tháng`;
  }
  if (duration_time % 12 == 0) {
    return `${duration_time / 12} năm`;
  } else {
    if (duration_time % 12 >= 10) {
      return `Gần ${Math.ceil(duration_time / 12)} năm`;
    }
    if (duration_time % 12 < 10) {
      if (duration_time <= 36) {
        return `${Math.floor(duration_time / 12)} năm ${
          duration_time % 12
        } tháng`;
      }
      if (duration_time > 36) {
        return `Hơn ${Math.floor(duration_time / 12)} năm`;
      }
    }
  }
};

export const getFirstAndLastElement = (array) => {
  if (array.length === 0) {
    return [];
  } else if (array.length === 1) {
    return [array[0]];
  } else {
    return [array[0], array[array.length - 1]];
  }
};

export const convertPhoneZero = (phone) => {
  // Chuyển đầu số 0 hoặc 84 thành +84
  phone = phone.replace(/^\+84/, "0").replace(/^84/, "0");
  return phone;
};

export const convertToMillionRange = (min, max) => {
  if (min === 0 && max === 0) {
    return "Miễn phí";
  }
  // Trường hợp chỉ có min bằng 0
  else if (min === 0 && max > 0) {
    const maxMillion = Math.round(max / 1000000);
    const maxThousand = Math.round(max / 1000);
    return max >= 1000000
      ? `0 - ${maxMillion} triệu`
      : `0 - ${maxThousand} nghìn`;
  } else if (!min) {
    return "";
  } else if (min === max) {
    // Trường hợp min bằng max
    if (min >= 1000000) {
      const minMillion = Math.round(min / 1000000);
      return `${minMillion} triệu`;
    } else {
      const minThousand = Math.round(min / 1000);
      return `${minThousand} nghìn`;
    }
  } else if (min >= 1000000 && max >= 1000000) {
    // Cả min và max đều lớn hơn 1,000,000
    const minMillion = Math.round(min / 1000000);
    const maxMillion = Math.round(max / 1000000);
    return `${minMillion} - ${maxMillion} triệu`;
  } else if (min < 1000000 && max >= 1000000) {
    // min nhỏ hơn 1,000,000 và max lớn hơn 1,000,000
    const minThousand = Math.round(min / 1000);
    const maxMillion = Math.round(max / 1000000);
    return `${minThousand} nghìn - ${maxMillion} triệu`;
  } else if (min < 1000000 && max < 1000000) {
    // Cả min và max đều nhỏ hơn 1,000,000
    const minThousand = Math.round(min / 1000);
    const maxThousand = Math.round(max / 1000);
    return `${minThousand} - ${maxThousand} nghìn`;
  } else {
    if (!min) {
      return `${max.toLocaleString("vi-VN")}`;
    } else if (!max) {
    }
    return `${min.toLocaleString("vi-VN")}`;
  }
};
export const shortenString = (s, maxLength = 16) => {
  if (!s) {
    return s;
  }
  if (s.length > maxLength) {
    return s.slice(0, maxLength - 3) + "...";
  }
  return s;
};

export const sanitizeLinks = (content) => {
  if (!content) return content;
  const invalidTagPattern =
    /<(a|iframe|embed|script|link|img)\b[^>]*(href|src)="https?:\/\/(?!nhakhoahub)[^"'\s]+[^>]*>(.*?)<\/\1>|<(a|iframe|embed|script|link|img)\b[^>]*(href|src)="https?:\/\/(?!nhakhoahub)[^"'\s]+[^>]*>/gi;

  // Thay thế các thẻ không hợp lệ
  content = content.replace(invalidTagPattern, "$5"); // Giữ lại nội dung văn bản nếu có

  // Lượt 2: Xóa các URL không hợp lệ
  const invalidLinkPatternURL = /https?:\/\/(?!nhakhoahub)[^"'\s\n]+/g;
  content = content.replace(invalidLinkPatternURL, ""); // Xóa link không hợp lệ

  return content;
};
export const filterOption = (input, option) => {
  console.log(input);
  console.log("op", option);
  if (option.props.value) {
    return strVNForSearch(option.props.children)?.includes(
      strVNForSearch(input)
    );
  } else {
    return false;
  }
};

export const shortenDescription = (description) => {
  return description?.replace(/<[^>]+>/g, "").slice(0, 150);
};

export const removeVietnameseAccents = (str) => {
  str = str.toLowerCase();
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
  str = str.replace(/đ/g, "d");
  return str;
};

//convert name to fill to avatar
export const abbreviateName = (fullName) => {
  const words = fullName?.trim()?.split(" ");

  if (!words || words.length === 0) {
    return ""; // Return an empty string if there are no words
  } else if (words.length === 1) {
    return words[0].charAt(0).toUpperCase();
  } else if (words.length === 2) {
    return words.map((word) => word.charAt(0).toUpperCase()).join("");
  } else {
    return (
      (words[0]?.charAt(0)?.toUpperCase() || "") +
      (words[words.length - 1]?.charAt(0)?.toUpperCase() || "")
    );
  }
};

export const handleSlug = (params, searchParams) => {
  const [category_service_slug, province_slug] = params.slug.includes("-tai-")
    ? params.slug.split("-tai-")
    : [params.slug, null];

  const categoryService = SERVICE.find(
    (item) => item.slug === category_service_slug
  );
  const province = province_slug
    ? PROVINCE_FOR_SELECT.find((item) => item.slug === province_slug)
    : PROVINCE_FOR_SELECT.find((item) => item.slug === params.slug);

  if (categoryService) {
    searchParams["category_service"] = categoryService.id;
    searchParams["category_service_name"] = categoryService.name;
    searchParams["category_service_slug"] = categoryService.slug;
  }
  if (province) {
    searchParams["province_id"] = province.value;
    searchParams["province_name"] = province.label;
    searchParams["province_slug"] = province.slug;

    if (searchParams.district) {
      const district = province.district.find(
        (item) => item.slug === searchParams.district
      );
      if (district) {
        searchParams["district_id"] = district.value;
        searchParams["district_name"] = district.label;
        searchParams["district_slug"] = district.slug;
      } else return notFound();
      if (searchParams.ward) {
        const ward = district.ward.find(
          (item) => item.slug === searchParams.ward
        );
        if (ward) {
          searchParams["ward_id"] = ward.value;
          searchParams["ward_name"] = ward.label;
          searchParams["ward_slug"] = ward.slug;
        } else return notFound();
      }
    }
  }
  return searchParams;
};

//title bannerSearch
export const generateTitle = ({
  categoryServiceName,
  provinceName,
  districtName,
}) => {
  const stringDate = new Intl.DateTimeFormat("en", {
    month: "2-digit",
    year: "numeric",
  }).format(new Date());

  const baseTitle = categoryServiceName
    ? `Top nha khoa ${categoryServiceName} uy tín`
    : "Top nha khoa uy tín";

  if (districtName && provinceName) {
    return `${baseTitle} tại ${districtName}, ${provinceName} ${stringDate}`;
  }

  if (provinceName) {
    return `${baseTitle} tại ${provinceName} ${stringDate}`;
  }
  if (categoryServiceName) {
    return `${baseTitle} ${stringDate}`;
  }
  return "Tìm kiếm nha khoa uy tín phù hợp nhất";
};

//get metadata phong-kham page
export const getMetadata = ({
  categoryServiceName,
  categoryServiceSlug,
  provinceName,
  provinceSlug,
  districtName,
  districtSlug,
  wardName,
  wardSlug,
  index,
}) => {
  const stringDate = new Intl.DateTimeFormat("en", {
    month: "2-digit",
    year: "numeric",
  }).format(new Date());

  const generateTitle = (location) =>
    `Top nha khoa ${
      categoryServiceName ? categoryServiceName + " " : ""
    }uy tín tại ${location} ${stringDate}`;

  let canonical = categoryServiceSlug ? `/${categoryServiceSlug}` : "";
  let title = "Top nha khoa uy tín " + stringDate;

  if (provinceSlug) {
    canonical += categoryServiceSlug
      ? `-tai-${provinceSlug}`
      : `/${provinceSlug}`;
    title = generateTitle(provinceName);
  }

  let canonicalParams = [];
  if (districtSlug) {
    canonicalParams.push(`district=${districtSlug}`);
    title = generateTitle(`${districtName}, ${provinceName}`);
  }
  if (wardSlug) {
    canonicalParams.push(`ward=${wardSlug}`);
    title = generateTitle(`${wardName}, ${districtName}, ${provinceName}`);
  }

  if (canonicalParams.length) {
    canonical += `?${canonicalParams.join("&")}`;
  }

  return {
    title,
    description: `${title} gần nhất với các dịch vụ về răng-hàm-mặt phù hợp, chất lượng.`,
    alternates: {
      canonical: `${process.env.NEXT_PUBLIC_DEPLOY_URL}/phong-kham${canonical}`,
    },
    robots: {
      index,
      follow: true,
      googleBot: {
        index,
        follow: true,
        "max-image-preview": "large",
        "max-video-preview": -1,
        "max-snippet": -1,
      },
    },
  };
};

export const prefixDoctor = (name) => {
  if (!name) return name;
  const normalizedName = name.toLowerCase();
  if (
    !normalizedName.includes("ts") &&
    !normalizedName.includes("tiến sĩ") &&
    !normalizedName.includes("bs") &&
    !normalizedName.includes("bác sĩ")
  ) {
    return `Bác sĩ ${name}`;
  }
  return name;
};

export const formatDateISO = (date = new Date()) => {
  return DateTime.fromJSDate(date instanceof Date ? date : new Date(date))
    .toUTC()
    .toISO();
};

export const convertToVietnamTime = (date) => {
  if (!date) return "";
  const dateTimeFormat = DateTime.fromFormat(date, "yyyy/MM/dd HH:mm:ss", {
    zone: "Asia/Ho_Chi_Minh",
  }).toISO({ suppressMilliseconds: true });
  return dateTimeFormat;
};
